import { getCookie, setCookie } from '@utils/cookies';
import { getLocation } from '@utils/locations';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  COOKIE_CONSENT_COOKIE_NAME,
  COUNTRIES,
  COUNTRY_COOKIE_NAME,
  CURRENCY_COOKIE_NAME,
  DEFAULT_COUNTRY_CODE,
  UNIT_COOKIE_NAME,
} from '../../../constants/locations';
import {
  defaultPreferences,
  UserPreferences,
  UserPreferencesContext,
} from '../../../context/UserPreferencesContext';
import {
  CookieConsentState,
  CountryCode,
  UnitsOfMeasure,
} from '../../../types/Locations';

type Props = {
  children?: React.ReactNode;
};

const UserPreferencesProvider = ({ children }: Props) => {
  const [preferences, setPreferences] =
    useState<UserPreferences>(defaultPreferences);

  const setCountry = (countryCode: CountryCode) => {
    setCookie(COUNTRY_COOKIE_NAME, countryCode);

    const details = COUNTRIES[countryCode] || COUNTRIES[DEFAULT_COUNTRY_CODE];

    setCookie(CURRENCY_COOKIE_NAME, details.currency);

    setPreferences((prev) => ({
      ...prev,
      countryCode,
      currency: details.currency,
    }));
  };

  const setUnitOfMeasurement = (unit: UnitsOfMeasure) => {
    setCookie(UNIT_COOKIE_NAME, unit);

    setPreferences((prev) => ({ ...prev, unitOfMeasurement: unit }));
  };

  const setCookieConsent = (state: CookieConsentState) => {
    setCookie(COOKIE_CONSENT_COOKIE_NAME, state);

    setPreferences((prev) => ({ ...prev, cookieConsent: state }));
  };

  const getInferredPreferences = useCallback(async () => {
    const results = await getLocation();
    if (results.error || !results.location?.country) return;
    const countryDetails =
      COUNTRIES[results.location.country] || COUNTRIES[DEFAULT_COUNTRY_CODE];

    const cookiePreferences = getCookie<CookieConsentState>(
      COOKIE_CONSENT_COOKIE_NAME,
    );

    setPreferences({
      countryCode: results.location.country,
      currency: countryDetails.currency,
      unitOfMeasurement: countryDetails.unit,
      cookieConsent: cookiePreferences || 'UNDECIDED',
    });
  }, []);

  useEffect(() => {
    getInferredPreferences();
  }, [getInferredPreferences]);

  const context = useMemo(
    () => ({
      preferences,
      setCountry,
      setUnitOfMeasurement,
      setCookieConsent,
    }),
    [preferences],
  );

  return (
    <UserPreferencesContext.Provider value={context}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;
