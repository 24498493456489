import { isBrowser } from '@utils/isBrowser';
import Script from 'next/script';
import React from 'react';

export const GoogleOAuth: React.FC = () => {
  if (!isBrowser()) return null;

  return (
    <Script
      defer
      src="https://accounts.google.com/gsi/client"
      strategy="lazyOnload"
    />
  );
};
