import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import type { ApplicationState } from './types';

export const useStore: TypedUseSelectorHook<ApplicationState> = useSelector;

export function createTakeEverySagaSet<TPayload>(
  actionCreator: ActionCreatorWithPayload<TPayload>,
  worker: (
    action: ReturnType<ActionCreatorWithPayload<TPayload>>,
  ) => Generator<unknown, unknown, unknown>,
) {
  return fork(function* watcher() {
    yield takeEvery(actionCreator.type, worker);
  });
}

export function createTakeEverySagaArray<TPayload>(
  actionCreators: ActionCreatorWithPayload<TPayload>[],
  worker: (
    action: ReturnType<ActionCreatorWithPayload<TPayload>>,
  ) => Generator<unknown, unknown, unknown>,
) {
  const watchers = actionCreators.map((actionCreator) => {
    return fork(function* watcher() {
      yield takeEvery(actionCreator.type, worker);
    });
  });

  return fork(function* watcher() {
    yield all(watchers);
  });
}

export function createTakeLatestSagaSet<TPayload>(
  actionCreator: ActionCreatorWithPayload<TPayload>,
  worker: (
    action: ReturnType<ActionCreatorWithPayload<TPayload>>,
  ) => Generator<unknown, unknown, unknown>,
) {
  return fork(function* watcher() {
    yield takeLatest(actionCreator.type, worker);
  });
}

export function createTakeLatestSagaArray<TPayload>(
  actionCreators: ActionCreatorWithPayload<TPayload>[],
  worker: (
    action: ReturnType<ActionCreatorWithPayload<TPayload>>,
  ) => Generator<unknown, unknown, unknown>,
) {
  const watchers = actionCreators.map((actionCreator) => {
    return fork(function* watcher() {
      yield takeLatest(actionCreator.type, worker);
    });
  });

  return fork(function* watcher() {
    yield all(watchers);
  });
}
