import { isBrowser } from '@utils/isBrowser';

function supportsLocalStorage() {
  if (!isBrowser()) return false;

  return typeof window.localStorage !== 'undefined';
}

export const getLocalStorage = (key: string): string => {
  if (!supportsLocalStorage()) return '';

  return window.localStorage.getItem(key) || '';
};

export const setLocalStorage = (key: string, value: string): void => {
  if (!supportsLocalStorage()) return;

  window.localStorage.setItem(key, value);
};

export const removeLocalStorageItem = (key: string): void => {
  if (!supportsLocalStorage()) return;

  window.localStorage.removeItem(key);
};
