/**
 * Run a series of async functions sequentially, without parallelism
 * @param funcs An array of functions which return promises
 * @param results Ignore this. Defaults to an empty array.
 * @returns An array of results from the given promises
 */
export const waterfall = <T>(
  funcs: (() => Promise<T>)[],
  results: T[] = [],
): Promise<T[]> => {
  if (funcs.length === 0) return Promise.resolve(results);

  return new Promise((resolve, reject) => {
    const [func, ...nextFuncs] = funcs;
    func()
      .then((result: T) => {
        const nextResults = [...results, result];
        resolve(waterfall(nextFuncs, nextResults));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const delay = (ms: number): Promise<undefined> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
