import { isBrowser } from '@utils/isBrowser';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import {
  UTM_CAMPAIGN_KEY,
  UTM_CONTENT_KEY,
  UTM_MEDIUM_KEY,
  UTM_SOURCE_KEY,
  UTM_TERM_KEY,
} from '../../../constants';
import { useDisableBodyScrolling } from '../../../hooks/useDisableBodyScrolling';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { Step, useFlow } from '../../../providers';
import { AnalyticsObjectProvider } from '../../../utils/analytics/object';
import { Modal } from '../../Dialog/Modal';
import { Header as StepContentHeader } from './Step/Header';

const modalPaddingTop = 4;
const maxScreenSize = '90dvh'; // For desktop & first screen on mobile
const PREFIX_ID_CONTENT = 'dialog-flow-content';
const PREFIX_ID_TITLE = 'dialog-flow-title';
const PREFIX_ID_FOOTER = 'dialog-flow-footer';

/**
 * This component renders the steps in a flow (from FlowContext) and handles the transitions when a user navigates between steps
 * @see DialogFlowContainer.tsx
 * @see useFlow.ts
 * @see FlowContext.tsx
 * */
export const FlowContent = () => {
  const { getSessionStorage } = useSessionStorage();

  const { closeFlow, currentStepIndex, flowConfig, goPrevStep, goToStep } =
    useFlow();
  const isMobile = useMediaQuery('sm');
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [titleHeight, setTitleHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  const recalculateHeights = (stepIndex: number | undefined) => {
    if (!flowConfig) return;
    const currentStep =
      stepIndex !== undefined ? flowConfig.steps[stepIndex] : undefined;

    // Main content of step
    const contentHeight =
      (currentStep
        ? document.getElementById(`${PREFIX_ID_CONTENT}-${currentStep?.id}`)
            ?.clientHeight
        : 0) ?? 0;

    // Fixed title component (contains title, close btn & back btn)
    const titleHeight =
      document.getElementById(`${PREFIX_ID_TITLE}-${currentStep?.id}`)
        ?.clientHeight ?? 0;

    // Optional footer component
    const footerHeight =
      document.getElementById(`${PREFIX_ID_FOOTER}-${currentStep?.id}`)
        ?.clientHeight ?? 0;

    setTitleHeight(titleHeight);
    setContentHeight(contentHeight);
    setFooterHeight(footerHeight);
  };

  // Recalculate the height of the modal on each step change in order to trigger animation
  useEffect(
    () => void recalculateHeights(currentStepIndex),
    [currentStepIndex],
  );

  // We only want the modal full screen on mobile and when not viewing the first step
  const isFirstStep = currentStepIndex === 0;
  const showFullScreen = !isFirstStep && isMobile;

  const titleHeightWithPadding = titleHeight + modalPaddingTop;
  const height = contentHeight + titleHeightWithPadding + footerHeight;

  if (!flowConfig) return null;

  const viewHeight = isBrowser() ? window.innerHeight : '90dvh';

  return (
    <div
      className={classNames(
        'theme-light mx-auto w-full overflow-hidden bg-white sm:max-w-md sm:rounded-b-lg',
        {
          'rounded-t-lg': !showFullScreen,
        },
      )}
      id="wrapper"
      style={{
        height: showFullScreen
          ? viewHeight
          : `min(${maxScreenSize}, ${height}px)`,
        transition: 'height 0.5s',
        display: 'grid',
        gridTemplateColumns: '1fr',
      }}
    >
      {flowConfig.steps.map((step, index) => {
        const isActive = index === currentStepIndex;

        return (
          <AnalyticsObjectProvider
            key={step.id}
            name={step.analytics.name}
            properties={{
              utm_term: getSessionStorage(UTM_TERM_KEY),
              utm_source: getSessionStorage(UTM_SOURCE_KEY),
              utm_medium: getSessionStorage(UTM_MEDIUM_KEY),
              utm_content: getSessionStorage(UTM_CONTENT_KEY),
              utm_campaign: getSessionStorage(UTM_CAMPAIGN_KEY),
              ...(step.analytics.properties ?? {}),
              ...(flowConfig.analyticsProperties ?? {}),
            }}
            sendViewedEvent={isActive}
          >
            {(emitter) => (
              <StepContent
                key={step.id}
                currentStepIndex={currentStepIndex}
                hideCloseButton={flowConfig.hideCloseButton ?? false}
                isActive={isActive}
                isFirstStep={isFirstStep}
                isProgressIndicatorVisible={
                  flowConfig.isProgressIndicatorVisible
                }
                onBack={() => {
                  emitter('back', 'clicked');
                  if (step.backStepId) {
                    goToStep(step.backStepId);
                  } else {
                    goPrevStep();
                  }
                }}
                onClose={() => {
                  emitter('close', 'clicked');
                  closeFlow();
                }}
                onHeightChange={recalculateHeights}
                showFullScreen={showFullScreen}
                step={step}
                stepCount={flowConfig?.steps.length}
              />
            )}
          </AnalyticsObjectProvider>
        );
      })}
    </div>
  );
};

interface StepProps {
  currentStepIndex: number | undefined;
  hideCloseButton: boolean;
  isActive: boolean;
  isFirstStep: boolean;
  onBack: () => void;
  onClose: () => void;
  onHeightChange: (currentStepIndex: number | undefined) => void;
  showFullScreen: boolean;
  step: Step;
  stepCount?: number;
  isProgressIndicatorVisible?: boolean;
}

const StepContent: React.FC<StepProps> = ({
  currentStepIndex,
  hideCloseButton,
  isActive,
  isFirstStep,
  isProgressIndicatorVisible,
  onBack,
  onClose,
  onHeightChange,
  showFullScreen,
  step,
  stepCount,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { content, footer, hideBackButton = false, id } = step;

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() =>
      onHeightChange(currentStepIndex),
    );
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
  }, [currentStepIndex]);

  return (
    <div
      className={classNames(
        'theme-light relative flex flex-col overflow-auto no-scrollbar',
        {
          'max-h-screen': showFullScreen,
        },
      )}
      style={{
        gridRowStart: 1,
        gridColumnStart: 1,
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.4s',
        zIndex: isActive ? 1 : 0,
      }}
    >
      <StepContentHeader
        hidden={Boolean(step.hideHeader)}
        id={`${PREFIX_ID_TITLE}-${id}`}
        isBackBtnVisible={!hideBackButton}
        isCloseBtnVisible={!hideCloseButton && !step.hideCloseButton}
        isIntro={isFirstStep}
        isProgressIndicatorVisible={
          isProgressIndicatorVisible &&
          step.isProgressIndicatorVisible !== false
        }
        onBack={onBack}
        onClose={onClose}
        progress={{
          currentIndex: currentStepIndex || 0,
          count: stepCount || 0,
        }}
        title={step.title}
      />
      <div
        className={classNames({
          'px-6 pb-6 pt-2': !step.hideSpacing,
        })}
        id={`${PREFIX_ID_CONTENT}-${id}`}
      >
        <div ref={elementRef}>{(isActive || step.forceRender) && content}</div>
      </div>

      {footer ? (
        <div className="sticky bottom-0" id={`${PREFIX_ID_FOOTER}-${id}`}>
          {footer}
        </div>
      ) : null}
    </div>
  );
};

const DialogFlowContainer: React.FC<{ containerId?: string }> = ({
  containerId = 'default',
}) => {
  const [mounted, setMounted] = useState(false);
  const { closeFlow, flowConfig, isFlowShown } = useFlow();
  const validContainer = flowConfig?.containerId === containerId;

  useDisableBodyScrolling(isFlowShown && validContainer);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted || !validContainer) {
    return null;
  }

  return (
    <Modal
      onClose={closeFlow}
      open={isFlowShown}
      title=""
      verticalAlign="center"
    >
      <FlowContent />
    </Modal>
  );
};

export default DialogFlowContainer;
