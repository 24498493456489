import { useEffect, useState } from 'react';

export const useScrollLock = (lock: boolean) => {
  const [everTriggered, setEverTriggered] = useState(lock);
  const html =
    typeof window === 'object'
      ? window?.document?.querySelector('html')
      : void 0;

  useEffect(() => {
    if (!html) return;

    if (lock) html.style.setProperty('overflow', 'hidden');
    else if (!lock && everTriggered) html.style.removeProperty('overflow');
  }, [lock, everTriggered, html]);

  useEffect(() => {
    if (!html) return void 0;

    return () => {
      html.style.removeProperty('overflow');
    };
  }, [html]);

  if (lock && !everTriggered) setEverTriggered(true);
};
