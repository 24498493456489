import { useState } from 'react';

import { FooterLocaleVisibilityContext } from '../../context/FooterLocaleVisibilityContext';
import { TrackDrawerClosed, TrackDrawerOpened } from '../../utils/analytics';

const FooterLocaleVisibilityProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [shown, setShown] = useState(false);
  const [recentLaunchLocation, setRecentLaunchLocation] = useState<
    undefined | string
  >(void 0);

  const show = (recentLaunchLocation: string) => {
    TrackDrawerOpened({
      context: 'location picker',
      source: recentLaunchLocation,
    });
    setRecentLaunchLocation(recentLaunchLocation);
    setShown(true);
  };

  const hide = () => {
    TrackDrawerClosed({ context: 'location picker' });
    setShown(false);
  };

  return (
    <FooterLocaleVisibilityContext.Provider
      value={{ shown, show, hide, recentLaunchLocation }}
    >
      {children}
    </FooterLocaleVisibilityContext.Provider>
  );
};

export default FooterLocaleVisibilityProvider;
