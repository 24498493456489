import React, { useEffect, useState } from 'react';

import { useDialogContext } from '../../../hooks/useDialogContext';
import { Modal } from '../../Dialog/Modal';

export const DialogContainer: React.FC = () => {
  const [mounted, setMounted] = useState(false);

  const { dialogContent, dialogOptions, hideDialog, open } = useDialogContext();

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Modal
      dismissible
      onClose={hideDialog}
      open={open}
      title=""
      {...dialogOptions}
    >
      {dialogContent}
    </Modal>
  );
};
