import { all, call, delay, put, select } from 'redux-saga/effects';

import { csrClient } from '../../../apollo/api/avantArteClient';
import { ApplicationState } from '../../../store/types';
import { createTakeEverySagaArray } from '../../../store/utils';
import { AvantArteDrawGateway } from '../gateways/AvantArteDrawGateway';
import { drawEntryActions as actions } from './draw-entry';
import { DrawEntryState } from './types';

const drawGateway = new AvantArteDrawGateway(csrClient);

export function* drawsSagas() {
  yield all([
    createTakeEverySagaArray(
      [actions.setupDrawEntry, actions.startPolling],
      function* startPollingSaga({ payload }) {
        const { actionId } = payload;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 10; i++) {
          const drawEntry = (yield select(
            (s: ApplicationState) => s.features.draw.drawEntry[actionId],
          )) as DrawEntryState;

          if (!drawEntry.polling || !drawEntry.entering) {
            break;
          }

          const dei = (yield call(
            [drawGateway, drawGateway.getDrawEntryIntent],
            drawEntry.drawEntryIntentId,
          )) as Awaited<ReturnType<typeof drawGateway.getDrawEntryIntent>>;

          if (!dei) {
            yield put(
              actions.markAsEntering({
                actionId,
                entering: false,
              }),
            );
            break;
          }

          const { confirmedAt, discardedAt, errorCode } = dei;

          switch (true) {
            case Boolean(errorCode):
              // TODO(afr): Handle this error
              console.error(errorCode);

              yield put(actions.stopPolling({ actionId }));
              yield put(actions.markAsEntering({ actionId, entering: false }));
              return;

            case !confirmedAt && !discardedAt:
              // Continue waiting
              break;

            case Boolean(confirmedAt): {
              yield put(actions.stopPolling({ actionId }));
              yield put(actions.markAsEntering({ actionId, entering: false }));
              yield put(actions.markAsEntered({ actionId, entered: true }));
              return;
            }

            case Boolean(discardedAt): {
              yield put(actions.stopPolling({ actionId }));
              yield put(actions.markAsEntering({ actionId, entering: false }));
              yield put(actions.markAsEntered({ actionId, entered: false }));
              return;
            }

            default:
              break;
          }

          // Wait for 2 seconds...
          yield delay(2000);
        }
      },
    ),
  ]);
}
