export const DrawActionTypes = {
  EXAMPLE: '@avantarte/marketplace/features/draw/EXAMPLE',
};

export interface DrawEntryState {
  entered?: boolean;
  freshEntry?: boolean;
  polling?: boolean;
  entering?: boolean;
  drawEntryIntentId: string;
}

export interface DrawState {
  drawEntry: Record<string, DrawEntryState | undefined>;
}

export const initialDrawState: DrawState = {
  drawEntry: {},
};
