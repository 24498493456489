import { createContext, useMemo } from 'react';

import { useOnboarding } from '../hooks/useOnboarding';

interface ContextType {
  onboarding?: ReturnType<typeof useOnboarding>;
}

export const AccountContext = createContext<ContextType>({
  onboarding: undefined,
});

interface Props {
  children: React.ReactNode;
}

export const AccountProvider = ({ children }: Props) => {
  const onboarding = useOnboarding();

  const context = useMemo(() => {
    return {
      onboarding,
    };
  }, [onboarding]);

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};
