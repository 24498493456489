/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

import { PayloadActionWithId } from '../../../store/types';
import { DrawEntryState, initialDrawState } from './types';

export interface SetupTrackerPayload extends DrawEntryState {
  drawEntryIntentId: string;
}

export const drawEntrySlice = createSlice({
  name: '@avantarte/marketplace/feature/draws/draw-entry',
  initialState: initialDrawState.drawEntry,
  reducers: {
    setupDrawEntry: (
      state,
      action: PayloadActionWithId<SetupTrackerPayload>,
    ) => {
      const { actionId, ...delta } = action.payload;

      state[actionId] = delta;
    },
    startPolling: (state, action: PayloadActionWithId) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.polling = true;
    },
    stopPolling: (state, action: PayloadActionWithId) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.polling = false;
      state[action.payload.actionId]!.entering = false;
    },

    markAsEntered: (
      state,
      action: PayloadActionWithId<{ entered: boolean }>,
    ) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.entered = action.payload.entered;
    },
    markAsEntering: (
      state,
      action: PayloadActionWithId<{ entering: boolean }>,
    ) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.entering = action.payload.entering;
    },
    markAsFreshEntry: (
      state,
      action: PayloadActionWithId<{ freshEntry: boolean }>,
    ) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.freshEntry = action.payload.freshEntry;
    },
    setDrawEntryIntentId: (
      state,
      action: PayloadActionWithId<{ drawEntryIntentId: string }>,
    ) => {
      if (!state[action.payload.actionId]) return;

      state[action.payload.actionId]!.drawEntryIntentId =
        action.payload.drawEntryIntentId;
    },
  },
});

export const drawEntryActions = drawEntrySlice.actions;
