import { createContext, useCallback, useState } from 'react';

import {
  NavigationState,
  NavigationStateContextType,
} from '../../../types/NavigationState';

export const NavigationStateContext = createContext<NavigationStateContextType>(
  {
    navigationState: undefined,
    setNavigationState: () => {},
    clearNavigationState: () => {},
  },
);

const NavigationStateProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [navigationState, setNavigationState] = useState<
    NavigationState | undefined
  >();

  const clearNavigationState = useCallback(() => {
    setNavigationState(undefined);
  }, [setNavigationState]);

  const context: NavigationStateContextType = {
    navigationState,
    setNavigationState,
    clearNavigationState,
  };

  return (
    <NavigationStateContext.Provider value={context}>
      {children}
    </NavigationStateContext.Provider>
  );
};

export default NavigationStateProvider;
