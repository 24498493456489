import type { providers } from 'ethers';
import dynamic from 'next/dynamic';
import React, { createContext, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { GeneratedDelegateCashOutput } from '../../../types/generated';
import { ChainId } from '../../../types/web3/web3';
import { Web3ProviderType } from '../../../types/web3/web3Context';

const ConnectWeb3WalletDrawer = dynamic(
  () => import('../Web3Drawers/connect/ConnectWeb3WalletDrawer'),
  { ssr: false },
);

export interface Web3CacheContext {
  isWeb3ProviderLoaded: boolean;
  setIsWeb3ProviderLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  web3Provider: providers.Web3Provider | undefined;
  setWeb3Provider: React.Dispatch<
    React.SetStateAction<providers.Web3Provider | undefined>
  >;
  chainId: ChainId | undefined;
  setChainId: React.Dispatch<React.SetStateAction<ChainId | undefined>>;
  walletAddress: string | undefined;
  setWalletAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
  isWeb3Connected: boolean | undefined;
  setIsWeb3Connected: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  signatures: Record<string, string>;
  setSignatures: React.Dispatch<
    React.SetStateAction<Record<string, string> | undefined>
  >;
  walletProvider: Web3ProviderType;
  setWalletProvider: React.Dispatch<
    React.SetStateAction<Web3ProviderType | undefined>
  >;
  ensAddress: string;
  setEnsAddress: React.Dispatch<React.SetStateAction<string>>;
  showConnectWalletDrawer: boolean;
  setShowConnectWalletDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isConnected: boolean;
  delegateCashStore: {
    delegateCashDelegations: GeneratedDelegateCashOutput[] | undefined;
    setDelegateCashDelegations: React.Dispatch<
      React.SetStateAction<GeneratedDelegateCashOutput[] | undefined>
    >;
    selectedDelegateCashDelegation: GeneratedDelegateCashOutput | undefined;
    setSelectedDelegateCashDelegation: React.Dispatch<
      React.SetStateAction<GeneratedDelegateCashOutput | undefined>
    >;
  };
}

export const web3CacheContext = createContext<Web3CacheContext>({
  delegateCashStore: {},
} as Web3CacheContext);

const Web3CacheContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [chainId, setChainId] = useState<ChainId | undefined>();
  const [walletAddress, setWalletAddress] = useState<string | undefined>(
    undefined,
  );
  const [isWeb3Connected, setIsWeb3Connected] = useLocalStorage<boolean>(
    'web3-is-connected',
    false,
  );
  const [signatures = {}, setSignatures] = useLocalStorage<
    Record<string, string>
  >('web3-signatures', {});
  const [walletProvider = Web3ProviderType.METAMASK, setWalletProvider] =
    useLocalStorage<Web3ProviderType>(
      'web3-provider',
      Web3ProviderType.METAMASK,
    );
  const [ensAddress, setEnsAddress] = useState<string>('');
  const [showConnectWalletDrawer, setShowConnectWalletDrawer] = useState(false);
  const [didLoadDrawer, setDidLoadDrawer] = useState(false);
  const [web3Provider, setWeb3Provider] = useState<providers.Web3Provider>();
  const [isWeb3ProviderLoaded, setIsWeb3ProviderLoaded] = useState(false);
  const [delegateCashDelegations, setDelegateCashDelegations] = useState<
    GeneratedDelegateCashOutput[] | undefined
  >();
  const [selectedDelegateCashDelegation, setSelectedDelegateCashDelegation] =
    useState<GeneratedDelegateCashOutput | undefined>();

  const context = {
    isWeb3ProviderLoaded,
    setIsWeb3ProviderLoaded,
    web3Provider,
    setWeb3Provider,
    chainId,
    setChainId,
    walletAddress,
    setWalletAddress,
    isWeb3Connected,
    setIsWeb3Connected,
    signatures,
    setSignatures,
    walletProvider,
    setWalletProvider,
    ensAddress,
    setEnsAddress,
    showConnectWalletDrawer,
    setShowConnectWalletDrawer: (is: boolean) => {
      if (is && didLoadDrawer) {
        setShowConnectWalletDrawer(is);
      } else {
        !didLoadDrawer && setDidLoadDrawer(true);
        // wait for the drawer to load so the animation would be smooth
        setShowConnectWalletDrawer(is);
      }
    },
    isConnected: !!web3Provider,
    delegateCashStore: {
      delegateCashDelegations,
      setDelegateCashDelegations,
      selectedDelegateCashDelegation,
      setSelectedDelegateCashDelegation,
    },
  };

  return (
    <web3CacheContext.Provider value={context}>
      {children}

      {didLoadDrawer && (
        <ConnectWeb3WalletDrawer
          onClose={() => setShowConnectWalletDrawer(false)}
          show={showConnectWalletDrawer}
        />
      )}
    </web3CacheContext.Provider>
  );
};

export default Web3CacheContextProvider;
