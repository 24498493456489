/**
 ****** @Deprecated ******
 ****** Please create new queries/mutations using .graphql files******
 */

import { gql } from '@apollo/client';

export const REFRESH_STREAM_TOKEN = gql`
  query RefreshStreamToken {
    refreshStreamToken
  }
`;

export const QUERY_ACCOUNT_IS_IN_DRAW = gql`
  query QueryAccountIsInDraw($id: ID!) {
    draw(id: $id) {
      accountIsInDraw
    }
  }
`;

export const GET_DRAW = gql`
  query draw($id: ID!) {
    draw(id: $id) {
      accountIsInDraw
      allocation
      openAt
      preOrderCloseAt
      totalAccounts
      accountIsInDraw
      status
      closeAt
      cryptoComEnabled
    }
  }
`;

export const GET_SHIPPING_RATE_BY_PRODUCT_VARIANT_ID = gql`
  query GetShippingRateByProductVariantID(
    $productVariantID: String!
    $countryCode: String!
    $currencyCode: String!
  ) {
    getShippingRateByProductVariantID(
      productVariantID: $productVariantID
      countryCode: $countryCode
      currencyCode: $currencyCode
    ) {
      currencyCode
      shippingRate
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts(
    $ids: [String!]
    $shopifyIDs: [String!]
    $slugs: [String!]
    $previewToken: String
  ) {
    getProducts(
      ids: $ids
      shopifyIDs: $shopifyIDs
      slugs: $slugs
      previewToken: $previewToken
    ) {
      id
      shopifyId
      artistIds
      slug
      title
      price
      medium
      releaseType
      availability
      isFrame
      drawLaunch
      editionSize
      cryptoDisplayPrice
      framingOffered
      framedDispatchDate
      framingUpsellEndTime
      shippingUpdateEndTime
      unframedDispatchDate
      tags
      launchAt
      closeAt
      variants {
        id
        productId
        shopifyId
        title
        sku
        available
        requiresShipping
        price
      }
      thumbnail {
        url
        width
        height
        alt
      }
      images {
        url
        width
        height
        alt
      }
      artists {
        name
        id
      }
    }
  }
`;

export const GET_TAXES_BY_COUNTRY_CODE = gql`
  query GetTaxesByCountryCode($countryCode: String!) {
    getTaxesByCountryCode(countryCode: $countryCode) {
      countryCode
      dutyTaxRate
      taxType
      vatRate
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddresses {
    addresses {
      id
      addressLine1
      addressLine2
      city
      countryCode
      countryName
      customsCode
      company
      firstName
      lastName
      phone
      provinceCode
      provinceName
      zip
      isDefault
    }
  }
`;

export const SEARCH_ARTISTS = gql`
  query SearchArtists($query: String!) {
    searchArtists(query: $query) {
      id
      name
      thumbnail
    }
  }
`;

export const GET_ARTISTS_BY_SLUG = gql`
  query GetArtistsBySlug($slugs: [String!]!) {
    artistsBySlug(slugs: $slugs) {
      id
      name
      slug
      thumbnail
      website
      instagram
      twitter
      tiktok
      ArteBio
    }
  }
`;

export const GET_NFT_VOUCHERS = gql`
  query nftsVouchers($productId: String!) {
    nftsGetVouchers(productId: $productId) {
      vouchers {
        claimed
        voucher {
          projectId
          walletAddress
          voucherId
          price
          validUntil
          tokenId
        }
      }
    }
  }
`;

export const GET_NFT_VOUCHER_BY_PRODUCT_NUMBER = gql`
  query nftVoucherByVoucherNumber($productId: String!, $voucherNumber: Int!) {
    nftVoucherByVoucherNumber(
      productId: $productId
      voucherNumber: $voucherNumber
    ) {
      voucher {
        projectId
        price
        validUntil
        tokenId
        nftContractAddress
        chainId
        voucherId
      }
      claimed
    }
  }
`;

export const GET_NFT_SIGNED_NFT_VOUCHER = gql`
  query nftGetSignedVoucher($voucherId: String!, $walletAddress: String!) {
    nftGetSignedVoucher(voucherId: $voucherId, walletAddress: $walletAddress) {
      claimed
      voucher {
        projectId
        walletAddress
        voucherId
        price
        validUntil
        tokenId
        signature
      }
    }
  }
`;

export const BLOCKCHAIN_GET_SIGNED_NFT_VOUCHER = gql`
  query blockchainGetSignedNftVoucher(
    $voucherId: String!
    $walletAddress: String!
  ) {
    blockchainGetSignedNftVoucher(
      voucherId: $voucherId
      walletAddress: $walletAddress
    ) {
      signature
      projectId
      walletAddress
      voucherId
      price
      validUntil
      tokenId
    }
  }
`;

export const VOUCHER_EXISTS_FOR_TOKEN_ID = gql`
  query voucherExistsForTokenId($projectId: String!, $tokenId: Int!) {
    voucherExistsForTokenId(projectId: $projectId, tokenId: $tokenId)
  }
`;

export const GET_NFT_PRINTS_FOR_PRODUCT = gql`
  query getNftPrintsForProduct($productId: String!) {
    getNftPrintsForProduct(productId: $productId) {
      prints {
        id
        tokenId
        owner
        authenticated
        orderedAt
      }
    }
  }
`;

export const BLOCKCHAIN_GET_ALL_NFTS_OF_CONTRACT = gql`
  query blockchainGetAllNftsOfContract(
    $input: BlockchainGetAllNftsOfContractInput!
  ) {
    blockchainGetAllNftsOfContract(input: $input) {
      token
      owner
    }
  }
`;

export const GET_FULL_NFT_PRINT = gql`
  query getFullNftPrint($productId: String!, $tokenId: String!) {
    getFullNftPrint(productId: $productId, tokenId: $tokenId) {
      ownerEmailHint
      print {
        id
        tokenId
        owner
        authenticated
        productId
        orderedAt
        ownerId
      }
      events {
        id
        owner
        authenticated
        productId
        createTime
        event
      }
    }
  }
`;

export const GET_OWNED_NFT_TOKENS_IN_CONTRACT = gql`
  query blockchainOwnedNftTokensInContract(
    $input: BlockchainOwnedNftTokensInContractInput!
  ) {
    blockchainOwnedNftTokensInContract(input: $input) {
      id
      metadataUri
    }
  }
`;

export const GET_IS_NFT_OWNED_BY_WALLET = gql`
  query blockchainIsNftTokenOwnedByWallet(
    $input: BlockchainIsNftTokenOwnedByWalletInput!
  ) {
    blockchainIsNftTokenOwnedByWallet(input: $input)
  }
`;

export const GET_VALIDATE_GATING = gql`
  query blockchainValidateGating($input: BlockchainValidateGatingInput!) {
    blockchainValidateGating(input: $input)
  }
`;

export const GET_INFO_FOR_TOKENS = gql`
  query blockchainGetInfoForTokens($input: BlockchainGetInfoForTokensInput!) {
    blockchainGetInfoForTokens(input: $input) {
      id
      metadataUri
      owner
    }
  }
`;

export const CALCULATE_ORDER_PRICING = gql`
  query calculateOrderPricing(
    $countryCode: String!
    $currencyCode: String!
    $items: [CalculateOrderPricingInputItem]!
  ) {
    calculateOrderPricing(
      countryCode: $countryCode
      currencyCode: $currencyCode
      items: $items
    ) {
      totalPrice
      subtotalTaxPrice
      subtotalShippingPrice
      subtotalItemsPrice
      currencyCode
      taxRate
      taxType
      breakdown {
        productId
        variantId
        title
        price
        tags
        carrierServiceName
        shippingProviderId
        quantity
        isTaxable
        freeShipping
        requiresShipping
      }
    }
  }
`;

export const GET_DRAW_BY_PRODUCT_ID = gql`
  query GetDrawByProductId($productId: String!) {
    getDrawByProductId(productId: $productId) {
      id
      productType
      drawType
      productId
      productVariantId
      preOrderCloseAt
      allocation
      accountIsInDraw
      closeAt
      openAt
      drawnAt
      status
      totalAccounts
      allocation
      cryptoComEnabled
    }
  }
`;

export const GET_DRAW_ENTRY_INTENT = gql`
  query GetDrawEntryIntent($drawEntryIntentId: ID!) {
    getDrawEntryIntent(id: $drawEntryIntentId) {
      id
      errorCode
      confirmedAt
      discardedAt
    }
  }
`;

export const LIST_PAYMENT_METHODS = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      providerId
      providerPaymentMethodId
      type
      default
      billingAddress {
        line1
        line2
        city
        state
        postalCode
        countryCode
      }
      card {
        brand
        expireMonth
        expireYear
        last4
        wallet {
          type
        }
      }
    }
  }
`;

/**
 ****** @Deprecated ******
 ****** Please create new queries/mutations using .graphql files******
 */
