import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';

import { useAuth } from '../providers';
import { TrackExperimentViewed } from '../utils/analytics';
import getAnonymousIdentifier from '../utils/anonymousIdentifier';

const growthbook = new GrowthBook({
  enableDevMode: true,
  trackingCallback(experiment, result) {
    TrackExperimentViewed({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

export const AAGrowthbookProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { user } = useAuth();
  const anonIdentifier = getAnonymousIdentifier();

  const userId = user?.id;

  useEffect(() => {
    fetch(process.env.NEXT_PUBLIC_GROWTHBOOK_ENVIRONMENT!)
      .then((res) => res.json())
      .then((json) => growthbook.setFeatures(json.features))
      .catch(console.error);

    const identifier = userId ?? anonIdentifier;

    growthbook.setAttributes({ id: identifier });
  }, [userId, anonIdentifier]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};
