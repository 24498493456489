import classNames from 'classnames';
import React from 'react';
import { Transition } from 'react-transition-group';

const defaultStyle: React.CSSProperties = {
  transition: 'all .3s ease-in-out',
};

const transitionAsideStyles: Record<string, React.CSSProperties> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

const transitionStyles: Record<string, React.CSSProperties> = {
  entering: { transform: 'translateY(120%)' },
  entered: { transform: 'translateY(0%)' },
  exiting: { transform: 'translateY(0%)' },
  exited: { transform: 'translateY(120%)' },
};

interface ModalCoverProps {
  display: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  overlayOtherDialogs: boolean;
  onKeyDown: (event: React.KeyboardEvent) => void;
  title: string;
  verticalAlign?: 'center' | 'flex-start' | 'flex-end';
}

export const ModalCover = React.forwardRef<
  HTMLDivElement | null,
  React.PropsWithChildren<ModalCoverProps>
>((props, ref) => {
  const {
    children,
    display,
    onClick,
    onKeyDown,
    overlayOtherDialogs = false,
    title,
    verticalAlign,
  } = props;

  const nodeRef = typeof ref === 'function' ? null : ref;

  return (
    <Transition in={display} nodeRef={nodeRef} timeout={300}>
      {(state) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <aside
          key={title}
          aria-label={title}
          aria-modal="true"
          className={classNames('modal-cover', {
            'pointer-events-none': !display,
            'pointer-events-auto': display,
            'z-modal': !overlayOtherDialogs,
            'z-secondaryModal': overlayOtherDialogs,
          })}
          onClick={onClick}
          onKeyDown={onKeyDown}
          role="dialog"
          style={{
            ...defaultStyle,
            ...transitionAsideStyles[state],
          }}
          tabIndex={-1}
        >
          <div
            ref={ref}
            className={classNames(
              'model-cover-children items-center',
              {
                'justify-end md:justify-center':
                  !verticalAlign || verticalAlign === 'center',
              },
              { 'justify-start': verticalAlign === 'flex-start' },
              { 'justify-end': verticalAlign === 'flex-end' },
            )}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        </aside>
      )}
    </Transition>
  );
});
