import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { drawReducer } from '../features/draws/store';
import { drawsSagas } from '../features/draws/store/sagas';
import { initialDrawState } from '../features/draws/store/types';
import { captureError } from '../utils/capture';
import { ApplicationState } from './types';

function createRootReducer() {
  return combineReducers<ApplicationState>({
    features: combineReducers({
      draw: drawReducer,
    }),
  });
}

function createInitialState(): ApplicationState {
  return {
    features: {
      draw: initialDrawState,
    },
  };
}

function* rootSaga() {
  yield all([fork(drawsSagas)]);
}

export function setupApplicationStore(): Store<ApplicationState> {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => captureError(error),
  });

  const initialState = createInitialState();
  const middleware = [sagaMiddleware];

  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
  });

  // Start the saga middleware
  sagaMiddleware.run(rootSaga);

  return store;
}
