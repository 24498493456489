import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type NavigationContextType = {
  mobileNavOpen: boolean;
  setMobileNavOpen: (value: boolean) => void;
};

const NavigationContext = createContext<NavigationContextType>({
  mobileNavOpen: false,
  setMobileNavOpen: () => null,
});

export const NavigationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const value = useMemo(
    () => ({
      mobileNavOpen,
      setMobileNavOpen,
    }),
    [mobileNavOpen, setMobileNavOpen],
  );

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
