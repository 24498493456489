import { createContext } from 'react';

import { ModalProps } from '../components/Dialog/Modal';

export type DialogOptions = Partial<ModalProps> & {
  overlayOtherDialogs?: boolean;
  verticalAlign?: 'center' | 'flex-start' | 'flex-end';
};
interface DialogContext {
  showDialog: (content: React.ReactNode, options?: DialogOptions) => void;
  hideDialog: () => void;
  dialogContent: React.ReactNode | null;
  dialogOptions?: DialogOptions;
  open: boolean;
}

export const DialogContext = createContext<DialogContext>({
  showDialog: () => {},
  hideDialog: () => {},
  open: false,
  dialogContent: null,
  dialogOptions: undefined,
});
